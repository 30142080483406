import Tobi from "@rqrauhvmra/tobi/js/tobi.js";

(function () {
  const $document = document,
    $tobiToggle = $document.querySelector("[data-lightbox]");

  /**
   * Stop an iframe or HTML5 <video> from playing
   * @param  {Element} element The element that contains the video
   */
  let stopVideos = function (element) {
    let iframe = element.querySelector("iframe"),
       video = element.querySelector("video" );

    if ( iframe ) {
      let iframeSrc = iframe.src;
      iframe.src = iframeSrc.replace('autoplay=1','');
    }
    if ( video ) {
      video.pause();
    }
  };

  (function init() {
    if (typeof($tobiToggle) != "undefined" && $tobiToggle != null) {

      let tobi = new Tobi({
        selector: "[data-lightbox]",
        autoplayVideo: true
      });



      let MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;

      let $tobi = document.querySelector('.tobi');
      // let iframe = document.querySelector("iframe");

      // iframe.setAttribute('allow', 'autoplay');

      let tobiObserver = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
          if (mutation.type == "attributes") {
            if (!tobi.isOpen()) {
              stopVideos($tobi);
            }
          }
        });
      });

      tobiObserver.observe($tobi, {
        attributes: true
      });

    }
  })();
})();
