/**
* Polyfills for IE
**/

require("./core/polyfill.js");


/**
* Default bundles features
**/

require("./core/collapse.js");
// require("./core/cookie.js");
require("./core/download-request.js");
// require("./core/dropzone.js");
require("./core/gallery.js");
require("./core/input.js");
// require("./core/upload.js");
require("./core/lazy-load.js");
// require("./core/modal.js");
// require("./core/newsletter.js");
// require("./core/pop-up.js");
require("./core/smooth-scroll.js");
// require("./core/sticky.js");
// require("./core/tooltip.js");
// require("./core/top.js");
require("./core/trigger.js");
// require("./core/upload.js");
require("./core/header.js");



/**
* Enable these features where needed
**/

//require("./extensions/aos.js");
//require("./extensions/count.js");
//require("./extensions/isotope.js");
//require("./extensions/placeholder.js");
//require("./extensions/slider.js");
//require("./extensions/title.js");
require("./extensions/tobi.js");



/**
* Copy over all images
**/

const images = require.context("../images", true, /\.(png|jpg|jpeg|gif|svg|webp)$/);
images.keys().forEach(images);
